<template>
  <div>
    <!-- product_details.html 12-53 -->
    <!--面包屑导航-->
    <div class="breadcrumb">
      <div class="container">
        <h2>
          <a href>首页</a>&gt; <a href>产品中心</a>&gt;
          <a href>净美仕净化器</a>
        </h2>
      </div>
    </div>
    <!--页面主体-->
    <div class="main container" v-if="data">
      <div class="pd_info clearfloat">
        <div class="pdinfo_img">
          <img :src="'http://3403649.xyz/' + data.pic" alt />
        </div>
        <div class="pdinfo_text">
          <h2>{{ data.title1 }}</h2>
          <ul>
            <li>型号：{{ data.model }}</li>
            <li>功能：{{ data.func }}</li>
            <li>噪音：{{ data.noise }}</li>
            <li>风量：{{ data.wind }}</li>
            <li>适用对象：{{ data.applyTo }}</li>
            <li>适用面积：{{ data.size }}</li>
            <li>空气净化能效等级：{{ data.level }}</li>
          </ul>
          <p>
            价格：
            <span>¥</span>
            <strong id="price">{{ data.price }}</strong>
          </p>
          <a @click="addCart" id="addCart">
            <span class="icon_cart"></span>加入购物车
          </a>
        </div>
      </div>
      <div class="details_box">
        <h2 class="title">产品详情</h2>
        <div class="pd_details" v-html="transHTML(data.detail)">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: 正常是用来接收属性传参的, 例如 <Product name="xxx" />
  // 此处就要写: props:['name']
  // 路由配置中, 书写 props:true 属性, 则 此处就可以利用 props 来接收路由参数
  props: ["pid"],
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    addCart() {
      let uid = this.$store.state.uid;
      if (uid) {
        let url = "cart_detail_add.php";
        let params = `uid=${uid}&pid=${this.pid}`;

        this.axios.post(url, params).then((res) => {
          console.log(res);

          let { code } = res.data;

          if (code == 1) {
            alert("添加成功!");
          } else {
            alert("添加失败!");
          }
        });
      } else {
        alert("登录之后, 才能进行添加操作!");

        this.$router.push({ path: "/login" });
      }
    },
    getData() {
      let url = "product_detail.php?pid=" + this.pid;

      this.axios.get(url).then((res) => {
        console.log(res);
        this.data = res.data;
      });
    },
    transHTML(html) {
      return html.replace(
        /src="product-imgs/g,
        'src="http://3403649.xyz/product-imgs'
      );
    },
  },
};
</script>

<style></style>
